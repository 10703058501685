import { httpService } from './axios.service'

const basicInfoService = {
  async getAllStreet () {
    try {


      const {
        status,
        data
      } = await httpService.get(process.env.VUE_APP_API_URL + '/api/street/index')

      if (status === 200) {
        return data
      }
    } catch (error) {
      return error
    }
  },

  async getAllHome (number) {
    try {
      const {
        status,
        data
      } = await httpService.get(process.env.VUE_APP_API_URL + '/api/street/home?numStreet=' + number)
      if (status === 200) {
        return data
      }
    } catch (error) {
      return error
    }
  }
}

export default basicInfoService
