<script setup>
import Navbar from '@/components/Navbar'
import listService from '../services/list.service'
import { ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const globalList = ref([])
const page = ref(0)

onMounted(async () => {
  globalList.value = await listService.get(1)
  await getPagination(globalList)
})

async function getPagination(globalList){
    page.value = globalList.value.count / globalList.value.size
}

watch(() => route.query.page , async(page) => {
  globalList.value = await listService.get(page)
})

</script>

<template>
  <section class="container-fluid">
    <Navbar/>
    <div class="row">
      <div class="col-sm-12">
        <div class="row border-bottom">
          <div class="col-6 col-sm-4 kadastr">Кадастровый номер</div>
          <div class="col-6 col-sm-4 street">Улица</div>
          <div class="col-6 col-sm-1 home">Дом</div>
          <div class="col-6 col-sm-3 size">Размер</div>
        </div>
        <div
          v-for="(one, index) in globalList.items"
          :key="index"
          class="row border-bottom border-dark"
        >
          <div class="col-6 col-sm-4 border kadastr">
              <router-link :to="{name: 'OneLand', params: {slug: one.id}}">{{ one.number }}</router-link>
          </div>
          <div class="col-6 col-sm-4 border street">{{ one.street }}</div>
          <div class="col-6 col-sm-1 border home">{{ one.home }}</div>
          <div class="col-6 col-sm-3 border size">{{ one.size }}</div>
        </div>
      </div>
    </div>
    <div class="row mt-1 ">
      <div class="col-12">

        <ul class="pagination pagination-month justify-content-center">
          <li v-for="(i, index) in page" :key="index"
              :class="{'page-item':true, 'active':route.query.page == i}">
            <router-link :to="{name: 'List', query: {page: i}}">
              <div class="page-link">{{i}}</div>
            </router-link>
           </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style scoped>
  @media (max-width: 576px) {
     .kadastr{
       order:1
     }
    .size{
      order: 2;
    }
    .street{
      order: 3;
    }
    .home{
      order: 4;
    }
  }
</style>
