import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/components/Login'
import List from '@/views/List'
import Finance from '@/views/Finance'
import Voting from '@/views/Voting'
import CheckIn from '@/components/CheckIn'
import NewCheck from '@/components/NewCheck'
import Maps from '@/views/Maps'
import tokenService from '../services/token.service'
import OneLand from '../views/OneLand.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {title: 'д.Веселево, КЗ1'}
  },
  {
    path: '/list',
    name: 'List',
    component: List,
  },
  {
    path: '/one-land/:slug(\\d+)',
    name: 'OneLand',
    component: OneLand
  },
  {
    path: '/finance',
    name: 'Finance',
    component: Finance
  },
  {
    path: '/voting',
    name: 'Voting',
    component: Voting
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {title: 'Авторизация'}
  },
  {
    path: '/check-in',
    name: 'CheckIn',
    component: CheckIn,
    meta: {title: 'Рагистрация'}
  },
  {
    path: '/checkin',
    name: 'NewCheck',
    component: NewCheck,
    meta: {title: 'Рагистрация'}
  },
  {
    path: '/maps',
    name: 'Maps',
    component: Maps,
    meta: {title: 'Карта КЗ1'}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = await tokenService.getToken()

  document.title = `${to.meta.title}`;
  if (to.name === 'CheckIn') {
    next()
  }
  if (to.name === 'Maps') {
    next()
  }
  if (to.name === 'NewCheck') {
    next()
  }
  if (to.name !== 'Login' && !token) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router;
