import { httpService } from './axios.service'

const projectService = {
    async list () {
        try{

          const result = await httpService.get(process.env.VUE_APP_API_URL + '/api/project/index')

          if (result.status === 200) {
            return result.data
          }

        } catch (e){
          return e
        }
    },

    async money (id) {
        try{

          const result = await httpService.get(process.env.VUE_APP_API_URL + '/api/money-project/view?id='+id)

          if (result.status === 200) {
            return result.data
          }
        } catch (e){
          return e
        }
    }
}

export default projectService
