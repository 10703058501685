import { httpService } from './axios.service'
import tokenService from './token.service'

const authService = {

  async login (dataPost) {
    try {

      const {status, data} = await httpService.post(process.env.VUE_APP_API_URL + '/api/user/login', dataPost)

      if (status === 200 && data.username) {
        await tokenService.setToken(data.access_token)

        return {
          success: true
        }
      }
      return {
        success: false
      }
    } catch (error) {
      return {
        success: false
      }
    }
  },
  async logout () {
    await tokenService.removeToken()
    return {
      success: true
    }
  }

}

export default authService
