<script setup>
import authService from '../services/auth.service'
import tokenService from '../services/token.service'
import { useToast } from 'vue-toastification'
import { useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'

const toast = useToast()
const router = useRouter()
const token = ref(null)

const fetchToken = async () => {
  token.value = await tokenService.getToken()
}
onMounted(fetchToken)


const links = [
  {
    key: 0,
    icon: '<i class="fas fa-house-user"/>',
    title: '',
    url: '/',
    access: '?'
  },
  {
    key: 1,
    title: 'Участки',
    url: '/list',
    icon: '',
    access: '@'
  },
  {
    key: 2,
    title: 'Финансы',
    url: '/finance',
    icon: '',
    access: '@'
  },
  {
    key: 3,
    title: 'Опросы',
    url: '/voting',
    icon: '',
    access: '@'
  },
  {
    key: 4,
    title: 'Карта поселка',
    url: '/maps',
    icon: '<i class="fas fa-map"/>',
    access: '?'
  }
]

async function logout () {
  const { success } = await authService.logout()
  if (success) {
    toast.success('Вы вышли из системы', {
      timeout: 5000
    })
    router.push('/login')
  }
}

function checkAccess(access) {
  if(token.value == null && access == '@') {
    return false
  }
  return true
}

</script>

<template>
  <nav class="navbar navbar-expand navbar-info navbar-dark">
    <ul class="navbar-nav">
      <li
        v-for="link in links"
        :key="link.key"
      >
        <router-link
          :to="link.url"
          class="nav-link"
          v-if="checkAccess(link.access)"
        >
          <span v-html="link.icon" />
          {{ link.title }}
        </router-link>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto" v-if="token">
      <li class="nav-item ">
        <a
          href="#"
          class="p-1"
        >
          <i class="fa fa-user text-white" />
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#"
          class="p-1"
          @click="logout"
        >
          <i class="fa fa-sign-out-alt text-white" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<style scoped>
.navbar-expand .navbar-nav{
    flex-wrap: wrap;
}
</style>
