<script setup>
import Navbar from '@/components/Navbar'
import { onMounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import listService from '../services/list.service'

const route = useRoute()

const oneLand = ref({})

onMounted(async() => {
  oneLand.value = await listService.one(route.params.slug)
})

const formattedNumber = computed(() => {
  const number = oneLand.value.user.number;
  return number.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1($2) $3-$4');
});
</script>

<template>
  <section class="container-fluid">
    <navbar/>
    <div class="row">
      <div class="col-sm-12">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><router-link :to="{name:'List'}">К списку</router-link></li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header bg-teal">
            {{ oneLand.street + ' ' + oneLand.home }}
          </div>
          <div class="card-body">
            <div class="text-center">Кадастровый номер: {{oneLand.number}}</div>
            <div class="text-center">Размер: {{oneLand.size}}</div>
            <p class="text-muted" v-if="oneLand.user">
            Собственник:  {{ oneLand.user.last_name + ' ' + oneLand.user.first_name }}
            </p>
            <p class="text-muted" v-if="oneLand.user">
             Почта: {{ oneLand.user.email }}
            </p>
            <p class="text-muted" v-if="oneLand.user">
             Телефон: <a :href="'tel:+' + oneLand.user.number">{{ formattedNumber }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
