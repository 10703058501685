import { httpService } from './axios.service'

const listService = {

  async get (page = 1) {
    try {

      const result = await httpService.get(process.env.VUE_APP_API_URL + '/api/list/index?page=' + page)

      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      return error
    }
  },

  async one(number) {
      try{
        const result = await httpService.get(process.env.VUE_APP_API_URL + '/api/list/view?id='+number)

        if (result.status === 200) {
          return result.data
        }
      } catch (error){
        return error
      }
  }
}

export default listService
