import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
//import './firebase-messaging-sw'
import router from './router'
import store from './store'
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { IMaskDirective } from 'vue-imask';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './index.scss'

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .directive('imask', IMaskDirective)
  .use(store)
  .use(Toast, {
    transition: 'Vue-Toastification__bounce',
    maxToasts: 20,
    newestOnTop: true
  })
  .use(router).mount('#app')
