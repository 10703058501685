<template>
  <section class="container-fluid">
    <navbar />
    <div class="container">
      <div style="min-height: 100px"></div>
    <h3>Раздел временно закрыт</h3>
    </div>
  </section>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  name: 'Finance',
  components: { Navbar }

}
</script>

<style scoped>

</style>
