import axios from 'axios'
import tokenService from './token.service'

export const httpService = {

   async post (url, data) {
     const token = await tokenService.getToken()
     const result = await axios(url, {
       method: 'POST',
       data: data,
       headers: {
         'Origin': process.env.VUE_APP_ORIGIN,
         'User-Agent': ' curl/7.51.0',
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${token}`
       }
     })

     return result
   },

  async get (url) {
    const token = await tokenService.getToken()

    const result = await axios(url, {
      method: 'GET',
      headers: {
       // 'Host': process.env.VUE_APP_ORIGIN,
        'User-Agent': ' curl/7.51.0',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })

    return result
  },

}
