const tokenService = {
 async getToken() {
    if(localStorage.getItem('authToken_bella') !== undefined){
      return localStorage.getItem('authToken_bella')
    }
    return null
  },
  async setToken(value)  {
    localStorage.setItem('authToken_bella', value)
  },
  async removeToken() {
   localStorage.removeItem('authToken_bella')
   return true
  },
  async rancidToken() {} // протухший токен

}

export default tokenService
